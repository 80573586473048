<template>
  <div class="login-page">
    <div class="logo" @click="toindex()">
      <img src="@/assets/logo.png" title="返回首页" />
    </div>
    <div class="bg">
      <div class="ikon">
        <img src="@/assets/ikon.png" />
        <div class="login-form">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="诚美商户登陆" name="first">
              <div class="tab">
                <el-radio v-model="radio" label="1">主账号登陆</el-radio>
                <el-radio v-model="radio" label="2">子账号登陆</el-radio>
              </div>
              <div>
                <label class="lab">手机号:</label>
                <input class="login_text" type="text" v-model="user" maxlength="11" @keyup="enable()"
                  placeholder="请输入手机号" />
              </div>
              <div>
                <label class="lab">登陆密码:</label>
                <input class="login_text" type="password" v-model="pwd" placeholder="请输入密码" />
              </div>
              <div style="position: relative">
                <label class="lab">图形验证码:</label><br>
                <input class="login_text" type="text" v-model="imgcode" maxlength="4"
                  style="width:160px;border-radius:0;" placeholder="请输入图形验证码" @keyup="enable()" />
                <div class="code" @click="refreshCode" v-if="activeName == 'first'">
                  <imgcode :identifyCode="identifyCode"></imgcode>
                </div>
              </div>
              <div style="position: relative">
                <label class="lab">手机验证码:</label><br>
                <input class="login_text" type="text" v-model="code" maxlength="6" style="width:160px;border-radius:0;"
                  placeholder="请输入手机验证码" />
                <div class="getcode" @click="sendverifycode()" v-if="code_show == true">{{ coded }}</div>
                <div class="getcode1" v-else-if="code_show == false">{{ coded }}</div>
              </div>
              <!-- <router-link tag="span" to="./reset_pwd"> -->
              <span class="forget-psd" @click="forGen">
                忘记密码？
              </span>
              <!-- </router-link> -->
              <div class="submit">
                <span @click="login()" class="logbtn">登录</span>
              </div>

            </el-tab-pane>
            <el-tab-pane label="虚拟商户登陆" name="third">
              <div class="tab">
                <!-- <el-radio v-model="radio2" label="1">主账号登陆</el-radio>
                <el-radio v-model="radio2" label="2">子账号登陆</el-radio> -->
                <el-radio v-model="radio" label="1">主账号登陆</el-radio>
                <el-radio v-model="radio" label="2">子账号登陆</el-radio>
              </div>
              <div>
                <label class="lab">手机号:</label>
                <input class="login_text" type="text" v-model="user" maxlength="11" @keyup="enable()"
                  placeholder="请输入手机号" />
              </div>
              <div>
                <label class="lab">登陆密码:</label>
                <input class="login_text" type="password" v-model="pwd" placeholder="请输入密码" />
              </div>
              <div style="position: relative">
                <label class="lab">图形验证码:</label><br>
                <input class="login_text" type="text" maxlength="4" v-model="imgcode"
                  style="width:160px;border-radius:0;" placeholder="请输入图形验证码" @keyup="enable()" />
                <div class="code" @click="refreshCode" v-if="activeName == 'third'">
                  <imgcode :identifyCode="identifyCode"></imgcode>
                </div>
              </div>
              <div style="position: relative">
                <label class="lab">手机验证码:</label><br>
                <input class="login_text" type="text" v-model="code" maxlength="6" style="width:160px;border-radius:0;"
                  placeholder="请输入手机验证码" />
                <div class="getcode" @click="sendverifycode()" v-if="code_show == true">{{ coded }}</div>
                <div class="getcode1" v-else-if="code_show == false">{{ coded }}</div>
              </div>
              <router-link tag="span" to="./reset_pwd">
                <span class="forget-psd">忘记密码？</span>
              </router-link>
              <div class="submit">
                <!-- <span @click="vir_login()" class="logbtn">登录</span> -->
                <span @click="login()" class="logbtn">登录</span>
              </div>
              <p class="to_reg">
                还没有开放平台充值账号？
                <span @click="toregister()">去注册</span>
              </p>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import imgcode from '@/components/imgcode'
export default {
  name: "Login",
  // inject:['app'],
  components: {
    imgcode,
  },
  data() {
    return {
      user: "",
      pwd: "",
      isreload: true,
      radio: '1',
      activeName: 'first',
      radio2: '1',
      code: '',
      coded: '获取验证码',
      totalTime: 60,
      code_show: false,
      imgcode: '',
      identifyCodes: "1234567890",
      identifyCode: "",
    };
  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  activated() {
    if (this.$store.state.login.islogin) {
      this.$store.state.login.islogin = false
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.reload()
    }
  },
  methods: {
    forGen() {
      this.$router.push('./reset_pwd')
    },
    enable() {
      if (this.user.toString().length == 11 && this.imgcode == this.identifyCode) {
        this.code_show = true;
      } else {
        this.code_show = false;
      }
    },
    sendverifycode() {
      var that = this;
      if (this.imgcode == this.identifyCode) {
        if (this.coded == '获取验证码') {
          this.coded = '发送中';
          let params = { 'phone': this.user };
          $.ajax({
            async: false,
            type: 'post',
            url: this.$store.state.user.q_url + '/api/api/sendSms_login',
            data: params,
            dataType: 'json',
            success: function (res) {
              that.refreshCode();
              if (that.totalTime == 60) {
                var bo_clock = window.setInterval(function () {
                  that.totalTime--;
                  that.coded = that.totalTime + 's';
                  if (that.totalTime < 0) {
                    window.clearInterval(bo_clock);
                    that.coded = '获取验证码';
                    that.totalTime = 60;
                  }
                }, 1000)
              }
            }, error: function () {
              that.coded = '获取验证码';
              this.$message({
                message: '发送失败',
                type: 'error',
                center: 'true'
              })
            }
          })
        }
      } else {
        this.$message({
          message: '图形验证码错误',
          type: 'error',
          center: 'true'
        })
        this.refreshCode();
      }

    },
    toindex() {
      this.$router.push("./homepage");
    },
    toregister() {
      this.$message({
        message: '暂时未开放注册！',
        type: 'error',
        center: 'true'
      })
      return;
      this.$router.push("./register");
    },
    handleClick(tab, event) {
    },
    login() {
      var that = this;
      if (this.radio == 1) {
        if (this.user == '' || this.pwd == '' || this.code == '') {
          if (this.user == '') {
            that.$message({
              message: '账号不能为空',
              type: 'error',
              center: 'true'
            })
          } else if (this.pwd == '') {
            that.$message({
              message: '密码不能为空',
              type: 'error',
              center: 'true'
            })
          } else if (this.code == '') {
            that.$message({
              message: '验证码不能为空',
              type: 'error',
              center: 'true'
            })
          }
        } else {
          let time = "78991474ea721226d3c39bdb4da52902" + this.user + this.pwd;
          let params = { 'user_name': this.user, 'password': this.pwd, 'code': this.code, 'session': time };
          $.ajax({
            async: false,
            type: 'post',
            url: this.$store.state.user.q_url + '/api/api/login',
            data: params,
            dataType: 'json',
            success: function (res) {
              if (res.success) {
                that.$message({
                  message: res.message,
                  type: 'success',
                  center: 'true',
                  offset: '60',
                });
                that.$store.commit('user/phone', res.data.phone)
                that.$store.commit('user/p_id', res.data.p_id)
                that.$store.commit('user/m_id', res.data.m_id)
                that.$store.commit('user/real_name', res.data.Real_name);
                that.$store.commit('user/u_type', res.data.virtual);
                that.$store.commit('user/reg_time', res.data.createtime);
                that.$store.commit('user/user_info', that.user);
                that.$store.commit("user/lsxd_open", res.data.lsxd_goods_open);
                that.$store.commit('login/login_info', 'true');
                that.$store.commit('user/dataVal', res.data);
                if (res.data.store_name != undefined) {
                  that.$store.commit('user/storename', res.data.store_name)
                }
                that.$store.commit('user/stock_remind', res.data.stock_remind);
                that.$router.push('/homepage');
              } else {
                that.$message({
                  message: res.message,
                  type: 'error',
                  center: 'true'
                })
              }
            },
          })
        }
      } else if (this.radio == 2) {
        if (this.user == '' || this.pwd == '' || this.code == '') {
          if (this.user == '') {
            that.$message({
              message: '账号不能为空',
              type: 'error',
              center: 'true'
            })
          } else if (this.pwd == '') {
            that.$message({
              message: '密码不能为空',
              type: 'error',
              center: 'true'
            })
          } else if (this.code == '') {
            that.$message({
              message: '验证码不能为空',
              type: 'error',
              center: 'true'
            })
          }
        } else {
          let time = "78991474ea721226d3c39bdb4da52902" + this.user + this.pwd;
          let params = { 'account': this.user, 'password': this.pwd, 'code': this.code, 'session': time };
          $.ajax({
            async: false,
            type: 'post',
            url: this.$store.state.user.q_url + '/api/api/sub_account_login',
            data: params,
            dataType: 'json',
            success: function (res) {
              if (res.success == true) {

                that.$store.commit('user/fid', res.data.fid);
                that.$store.commit('user/p_id', res.data.fid);
                that.$store.commit('user/m_id', res.data.m_id);
                that.$store.commit('user/storename', res.data.account);
                that.$store.commit('user/user_info', res.data.account);
                that.$store.commit('user/phone', res.data.phone);
                that.$store.commit('user/sub_id', res.data.sub_id);
                that.$store.commit('user/goodstype', res.data.goods_type);
                that.$store.commit('user/sub_status', res.data.sub_status);
                that.$store.commit('user/sub_power', res.data.sub_power);
                that.$store.commit('user/real_name', res.data.Real_name);
                that.$store.commit('login/login_info', 'true');
                that.$store.commit('user/dataVal', res.data);
                if (res.data.power_open == 1) {
                  that.$message({
                    message: res.message,
                    type: 'success',
                    center: 'true',
                    offset: '60',
                  });
                  
                  that.$router.push('/my_manage');
                  sessionStorage.setItem("store", JSON.stringify(that.$store.state))
                } else {
                  that.$message({
                    message: res.message,
                    type: 'success',
                    center: 'true',
                    offset: '60',
                  });
                  that.$router.push('/sub_order');
                  sessionStorage.setItem("store", JSON.stringify(that.$store.state))

                  // that.$message.error('请联系商务开通权限')
                }
                // that.$router.push('/sub_manage');


              } else {
                that.$message({
                  message: res.message,
                  type: 'warning',
                  center: 'true',
                  offset: '60',
                });
              }
            },
          })
        }

      }

    },
    vir_login() {
      var that = this;
      if (this.radio2 == 1) {
        if (this.user == '' || this.pwd == '' || this.code == '') {
          if (this.user == '') {
            that.$message({
              message: '账号不能为空',
              type: 'error',
              center: 'true'
            })
          } else if (this.pwd == '') {
            that.$message({
              message: '密码不能为空',
              type: 'error',
              center: 'true'
            })
          } else if (this.code == '') {
            that.$message({
              message: '验证码不能为空',
              type: 'error',
              center: 'true'
            })
          }
        } else {
          let time = "78991474ea721226d3c39bdb4da52902" + this.user + this.pwd;
          let params = { 'userName': this.user, 'password': this.pwd, 'code': this.code, 'session': time };
          $.ajax({
            async: false,
            type: 'post',
            url: this.$store.state.user.q_url + '/api/api/xn_login',
            data: params,
            dataType: 'json',
            success: function (res) {
              if (res.success) {
                that.$message({
                  message: res.message,
                  type: 'success',
                  center: 'true',
                  offset: '60',
                });
                that.$store.commit('user/phone', res.data.phone)
                that.$store.commit('user/u_id', res.data.user_id)
                that.$store.commit('user/m_id', res.data.m_id)
                that.$store.commit('user/u_type', res.data.status);
                that.$store.commit('user/vir_id', res.data.user_id);
                that.$store.commit('user/real_name', res.data.Real_name);
                that.$store.commit('user/user_info', that.user);
                that.$store.commit('login/login_info', 'true');
                that.$store.commit('user/reg_time', res.data.regdate);
                that.$store.commit('user/dataVal', res.data);
                if (res.data.store_name != undefined) {
                  that.$store.commit('user/storename', res.data.store_name)
                }
                that.$router.push('/homepage');
              } else {
                that.$message({
                  message: res.message,
                  type: 'error',
                  center: 'true'
                })
              }
            },
          })
        }
      } else if (this.radio2 == 2) {
        if (this.user == '' || this.pwd == '' || this.code == '') {
          if (this.user == '') {
            that.$message({
              message: '账号不能为空',
              type: 'error',
              center: 'true'
            })
          } else if (this.pwd == '') {
            that.$message({
              message: '密码不能为空',
              type: 'error',
              center: 'true'
            })
          } else if (this.code == '') {
            that.$message({
              message: '验证码不能为空',
              type: 'error',
              center: 'true'
            })
          }
        } else {
          let time = "78991474ea721226d3c39bdb4da52902" + this.user + this.pwd;
          let params = { 'account': this.user, 'password': this.pwd, 'code': this.code, 'session': time };
          $.ajax({
            async: false,
            type: 'post',
            url: this.$store.state.user.q_url + '/api/api/sub_account_login',
            data: params,
            dataType: 'json',
            success: function (res) {
              if (res.success) {
                that.$message({
                  message: res.message,
                  type: 'success',
                  center: 'true',
                  offset: '60',
                });
                that.$store.commit('user/fid', res.data.fid);
                that.$store.commit('user/p_id', res.data.fid);
                that.$store.commit('user/m_id', res.data.m_id);
                that.$store.commit('user/storename', res.data.account);
                that.$store.commit('user/user_info', res.data.account);
                that.$store.commit('user/phone', res.data.phone);
                that.$store.commit('user/goodstype', res.data.goods_type);
                that.$store.commit('user/sub_status', res.data.sub_status);
                that.$store.commit('user/sub_power', res.data.sub_power);
                that.$store.commit('user/real_name', res.data.Real_name);
                that.$store.commit('login/login_info', 'true');
                that.$store.commit('user/dataVal', res.data);

                that.$router.push('/sub_manage');
              } else {
                that.$message({
                  message: res.message,
                  type: 'warning',
                  center: 'true',
                  offset: '60',
                });
              }
            },
          })
        }

      }

    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
  },
  watch: {
    activeName(val) {
      if (this.activeName == "first") {
        this.refreshCode();
      } else if (this.activeName == "third") {
        this.refreshCode();
      }
    }
  }
};

</script>

<style lang="less" scoped>
.tab {
  height: 20px;
  margin: 0 auto 5px auto;
  border: 1xp solid #000;
}

.login-page {
  // top: 80px;
  // position: fixed;
  padding: 0px;
  margin: 0;
  font-size: 0px;
  height: 80%;
  width: 100%;
  box-sizing: border-box;

  .bg {
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    min-width: 1100px;
    height: 670px;
    // min-height: 670px;
    background: url(../../assets/Login_bg.png) no-repeat;
    background-size: 1920px 670px;
    opacity: 0.9;
    z-index: -10;
  }
}

.logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: 15px;

  >img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.ikon {
  position: absolute;
  z-index: 5;
  padding: 0;
  align-items: center;
  width: 1000px;
  height: 500px;
  margin: auto;
  top: 13%;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  overflow: hidden;

  >img {
    width: 570px;
    height: 100%;
  }

  .login-form {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    height: 500px;
    width: 430px;
    background-color: #fff;
    box-sizing: border-box;

    >p {
      width: 210px;
      height: 20px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
    }

    .lab {
      width: 51px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 33px;
    }

    .login_text {
      width: 240px;
      height: 40px;
      border: none;
      outline: none;
      text-indent: 10px;
      padding-left: 10px;
      font-size: 14px;
      background: rgba(238, 238, 238, 1);
      border-radius: 20px;
      z-index: 10;
      display: block;
    }

    .forget-psd {
      width: 60px;
      height: 12px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #3D31E2;
      line-height: 31px;
      display: block;
      position: relative;
      left: 190px;
      cursor: pointer;
    }

    .submit {
      height: 50px;
      margin: 10px;
      cursor: pointer;
    }

    .submit .logbtn {
      width: 240px;
      height: 40px;
      background: rgba(55, 67, 214, 1);
      border-radius: 20px;
      display: block;
      color: #fff;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 33px;
      text-align: center;
      margin-top: 30px;
    }

    .getcode {
      width: 100px;
      height: 35px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(55, 67, 214, 1);
      display: block;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(55, 67, 214, 1);
      line-height: 35px;
      text-align: center;
      margin-left: 8px;
      cursor: pointer;
      position: absolute;
      left: 160px;
      top: 37px;
    }

    .getcode1 {
      width: 100px;
      height: 35px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid #aaa;
      display: block;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #aaa;
      line-height: 35px;
      text-align: center;
      margin-left: 8px;
      position: absolute;
      left: 160px;
      top: 37px;
    }

    .to_reg {
      width: 200px;
      height: 12px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      line-height: 12px;
      margin-left: 30px;

      >span {
        color: #2860ea;
        cursor: pointer;
      }
    }
  }

  .code {
    margin: 10px auto;
    width: 114px;
    height: 40px;
    // border: 1px solid red;
    cursor: pointer;
    position: absolute;
    top: 25px;
    left: 160px;
  }

  .login-form /deep/ .el-tabs__nav {
    width: 430px;
    border-radius: 0px 0px 0px 0px;
    border: none;
  }

  .login-form /deep/ .el-tabs__nav .el-tabs__item {
    width: 240px;
    border-radius: 2px 30px 2px 2px;
    overflow: hidden;
    margin-left: -22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    padding-left: 60px;
    border: none;
  }

  // .login-form /deep/ .el-tabs__nav .el-tabs__item:hover{color: rgb(224, 44, 32)}
  .login-form /deep/ .el-tabs__content {
    height: 440px;
  }

  .login-form /deep/ .el-tab-pane {
    width: 430px;
    height: 440px;
    font-size: 16px;
    padding: 0 80px;
  }

  .login-form /deep/ .is-active {
    background-color: #3D31E2 !important;
    color: #fff;
    font-size: 20px;
    z-index: 500;
  }

  .login-form /deep/ #tab-third {
    padding-left: 30px;
    margin-left: -25px;
    z-index: 1;
  }

  .login-form /deep/ #tab-first {
    border-radius: 2px 30px 2px 2px;
    margin-left: -15px;
    z-index: 400;
  }

  .login-form /deep/ .is-checked .el-radio__inner {
    border-color: #3D31E2;
    background-color: #3D31E2;
  }

  .login-form /deep/ .is-checked+.el-radio__label {
    color: #3D31E2;
    font-weight: 400;
  }
}
</style>