import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueRouter from "vue-router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import VueAxios from "vue-axios";
import Qs from "qs";
import Vuex from "vuex";
import $ from "jquery";
import imgcode from './components/imgcode';
// import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import 'lib-flexible/flexible';
import Clipboard from 'clipboard';
import VueBus from 'vue-bus';


var axios_instance = axios.create({
  // config里面有这个transformRquest， 这个选项会在发送参数前进行处理。 这时候我们通过Qs.stringify转换为表单查询参数
  transformRequest: [function (data) {
    data = Qs.stringify(data);
    return data;
  }],
  // 设置Content - Type
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})
Vue.prototype.$axios = axios;
Vue.prototype.$qs = Qs;
Vue.prototype.Clipboard = Clipboard;
Vue.config.productionTip = false;

Vue.use(ElementUI, { size: "small", zIndex: 3000 });
// Vue.use(ViewUI);
Vue.use(VueBus);
Vue.use(imgcode);
Vue.use(VueAxios, axios_instance);
Vue.use(Vuex);
Vue.use(VueRouter);

// router.beforeEach((to, from, next) => {
//   // 可以在路由元信息指定哪些页面需要登录权限
//   const islogin = store.getters.getlogin_state; // 假设没有登录（这里应从接口获取）
//   if (!islogin) {
//      // 需要登录授权，这里还需要判断是否登录
//     next('./homepage') // 跳转到登录
//     return
//   }
//   next()
// });
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
