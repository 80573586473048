import Vue from "vue";
import VueRouter from "vue-router";
import Loginrouter from "./Login";
import registerrouter from "./register";
import verifyrouter from "./verify";
import register_third from "./register_third";
import verify_third from "./verify_third";
import Resetpwdrouter from "./resetpwd";
import Resetnewpwd from "./resetnew";
import Resetcomplate from "./reset_complated";
import Homepagerouter from "./homepage";
import Suppliers from "./suppliers";
import applyclass from "./apply_class";
import applycomplated from "./apply_complated";
import Agentrouter from "./agent";
import Agentfinish from "./agent_finish";
import Agentfaliure from "./agent_failure";
import HelpCenter from "./help_center";
import In_mail from "./In_mail";
import my_manage from "./my_manage";
import submanage from "./sub_account"
import vlogin from "./vlogin";
import Supchecking from "./sup_checking"
import Supfinish from "./sup_finish"
import Txorder from "./tx_order"
import newIqiyi from "./new_iqiyi_vip"

Vue.use(VueRouter);

const routes = [
  Loginrouter,
  registerrouter,
  verifyrouter,
  register_third,
  verify_third,
  Resetpwdrouter,
  Resetnewpwd,
  Resetcomplate,
  Homepagerouter,
  Suppliers,
  applyclass,
  applycomplated,
  Agentrouter,
  Agentfinish,
  Agentfaliure,
  HelpCenter,
  In_mail,
  my_manage,
  submanage,
  vlogin,
  Supchecking,
  Supfinish,
  Txorder,
  newIqiyi,
  {
    path: "/",
    redirect: "/homepage",
  },
  {
    path: '/iqiyi_vip',
    component: () => import('@/views/vips/iqiyi_vip.vue')
  },
  {
    path: '/youku_vip',
    component: () => import('@/views/vips/youku_vip.vue')
  },
  {
    path: '/mangguo_vip',
    component: () => import('@/views/vips/mangguo_vip.vue')
  },
  {
    path: '/viporder',
    component: () => import('@/views/vips/pinpaiorder.vue')
  }, {
    path: '/baidyun',
    component: () => import('@/views/vips/baidyun.vue')
  }, {
    path: '/allNewh5',
    component: () => import('@/views/vips/allNewh5.vue')
  }, {
    path: '/mtvoucher',
    component: () => import('@/views/vips/mtvoucher.vue')
  }, {
    path: '/yhuika',
    component: () => import('@/views/vips/yhuika.vue')
  }, {
    path: '/music',
    component: () => import('@/views/vips/music.vue')
  }, {
    path: '/xmly',
    component: () => import('@/views/vips/xmly.vue')
  }, {
    path: '/chengmeiautologin',
    component: () => import('@/components/Loginon/chengmeiautologin.vue')
  }, {
    path: '/music_qq',
    component: () => import('@/views/kefu/qqyy.vue')
  }, {
    path: '/music_wyun',
    component: () => import('@/views/kefu/wyun.vue')
  }, {
    path: '/music_yku',
    component: () => import('@/views/kefu/yku.vue')
  }, {
    path: '/qq_music',
    component: () => import('@/views/music/qq_music.vue')
  }, {
    path: '/tb_vpund',
    component: () => import('@/views/vips/tb_vpund.vue')
  }, {
    path: '/weixin',
    component: () => import('@/views/vips/weixin.vue')
  }, {
    path: '/sub_order',
    component: () => import('@/components/sub_order')
  }
];
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  //mode:'history',
  routes
});

export default router;
