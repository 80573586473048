const state = {
    supplier_list: [{
        Supplier_status: 1000,
        Supplier_name: '外部供应商'
    },
    {
        Supplier_status: 0,
        Supplier_name: '其他'
    },
    {
        Supplier_status: 1,
        Supplier_name: '易派网'
    },
    {
        Supplier_status: 2,
        Supplier_name: '卡商网'
    },
    {
        Supplier_status: 3,
        Supplier_name: '交壹吧'
    },
    {
        Supplier_status: 4,
        Supplier_name: '福禄（好不好公司）'
    },
    {
        Supplier_status: 5,
        Supplier_name: '硕软网'
    },
    {
        Supplier_status: 6,
        Supplier_name: '凤凰数卡'
    },
    {
        Supplier_status: 7,
        Supplier_name: '承天下'
    },
    {
        Supplier_status: 8,
        Supplier_name: '橙券'
    },
    {
        Supplier_status: 9,
        Supplier_name: '彦浩'
    },
    {
        Supplier_status: 10,
        Supplier_name: '蓝色兄弟【未税】'
    },
    {
        Supplier_status: 11,
        Supplier_name: '达尚'
    },
    {
        Supplier_status: 12,
        Supplier_name: '云金'
    },
    {
        Supplier_status: 13,
        Supplier_name: '子轩'
    },
    {
        Supplier_status: 14,
        Supplier_name: '娱尚'
    },
    {
        Supplier_status: 15,
        Supplier_name: '凯捷数娱'
    },
    {
        Supplier_status: 16,
        Supplier_name: '商盟'
    },
    {
        Supplier_status: 17,
        Supplier_name: '乐娱'
    },
    {
        Supplier_status: 18,
        Supplier_name: '裕寅旺'
    },
    {
        Supplier_status: 19,
        Supplier_name: '百妙'
    },
    {
        Supplier_status: 20,
        Supplier_name: '旧梦'
    },
    {
        Supplier_status: 21,
        Supplier_name: '雷鸟'
    },
    {
        Supplier_status: 22,
        Supplier_name: '迅银'
    },
    {
        Supplier_status: 23,
        Supplier_name: '乐充'
    },
    {
        Supplier_status: 24,
        Supplier_name: '分销E'
    },
    {
        Supplier_status: 25,
        Supplier_name: '恒易迅'
    },
    {
        Supplier_status: 26,
        Supplier_name: '西柚'
    }, {
        Supplier_status: 27,
        Supplier_name: '拉布拉卡'
    },
    {
        Supplier_status: 28,
        Supplier_name: '胜始科技带票'
    },
    {
        Supplier_status: 29,
        Supplier_name: '恒易迅带票'
    },
    {
        Supplier_status: 30,
        Supplier_name: '樊登读书'
    },
    {
        Supplier_status: 31,
        Supplier_name: '农行兑换'
    },
    {
        Supplier_status: 32,
        Supplier_name: '京东兑换'
    },
    {
        Supplier_status: 34,
        Supplier_name: '本香'
    },
    {
        Supplier_status: 35,
        Supplier_name: '86885'
    },
    {
        Supplier_status: 36,
        Supplier_name: '喜马拉雅'
    },
    {
        Supplier_status: 37,
        Supplier_name: '樊登会员'
    },
    {
        Supplier_status: 38,
        Supplier_name: '雪鹰网络'
    },
    {
        Supplier_status: 39,
        Supplier_name: '招商爱奇艺'
    },
    {
        Supplier_status: 40,
        Supplier_name: '拉布拉卡爱奇艺'
    },
    {
        Supplier_status: 41,
        Supplier_name: '昊天'
    },
    {
        Supplier_status: 42,
        Supplier_name: '拉布拉卡腾讯月'
    },
    {
        Supplier_status: 43,
        Supplier_name: 'sctq8.cn爱奇艺月卡'
    },
    {
        Supplier_status: 44,
        Supplier_name: '橙券卡密'
    },
    {
        Supplier_status: 45,
        Supplier_name: 'https://web.g-town.com.cn/boc_coupon/index.html'
    },
    {
        Supplier_status: 46,
        Supplier_name: 'jifenfu.net'
    },
    {
        Supplier_status: 47,
        Supplier_name: '酷开skysrt.com'
    },
    {
        Supplier_status: 64,
        Supplier_name: '福禄2次（轻松办公司）'
    },
    {
        Supplier_status: 65,
        Supplier_name: '福禄3次（拔刀公司无票）'
    },
    {
        Supplier_status: 66,
        Supplier_name: '好不好新平台'
    },
    {
        Supplier_status: 67,
        Supplier_name: '昊天新平台'
    },
    {
        Supplier_status: 68,
        Supplier_name: '胜始科技不带票'
    },
    {
        Supplier_status: 69,
        Supplier_name: 'https://card.renyuejt.com/#KX2'
    },
    {
        Supplier_status: 70,
        Supplier_name: '70dg.cn兑换'
    },
    {
        Supplier_status: 71,
        Supplier_name: '可近数娱'
    },
    {
        Supplier_status: 72,
        Supplier_name: '清晨网络【福纵公司】'
    },
    {
        Supplier_status: 73,
        Supplier_name: 'https://mall.jw2008.cn/easyExchange?from=1&smsSpecId=243&appkey=ZDLq9Vks6B7g0Qsk5CeOt7MkVD92Bq'
    },
    {
        Supplier_status: 74,
        Supplier_name: '漫途科技'
    },
    {
        Supplier_status: 75,
        Supplier_name: '江苏校园'
    },
    {
        Supplier_status: 76,
        Supplier_name: 'https://shry.vip/#5om'
    },
    {
        Supplier_status: 77,
        Supplier_name: 'http://card.weilian.xin'
    },
    {
        Supplier_status: 78,
        Supplier_name: 'https://api.jifenfu.net/fun_recharge/html/index.html?type=18'
    },
    {
        Supplier_status: 79,
        Supplier_name: '皮皮-福慧科技'
    },
    {
        Supplier_status: 80,
        Supplier_name: '昊天卡卡云'
    },
    {
        Supplier_status: 81,
        Supplier_name: 'https://mall.jw2008.cn/easyExchange?from=1&smsSpecId=360&appkey=VcbUzq6cbtC6hfI6H9FfUMXZeYYYHA'
    },
    {
        Supplier_status: 82,
        Supplier_name: 'https://wechat.chengquan.cn/toExchange/lianxiang'
    },
    {
        Supplier_status: 83,
        Supplier_name: 'https://hemi.lianhaikeji.com/e6e1aa64/merchant/remote/common/getTicket/index'
    },
    {
        Supplier_status: 84,
        Supplier_name: 'http://150.158.130.124:8585'
    },
    {
        Supplier_status: 85,
        Supplier_name: 'https://vip.heydui.cn/recharge/index?pt_no=SHCYXXKJYXGS0031'
    },
    {
        Supplier_status: 86,
        Supplier_name: 'https://mall.jw2008.cn/easyExchange?from=1&smsSpecId=631&appkey=ZDLq9Vks6B7g0Qsk5CeOt7MkVD92Bq'
    },
    {
        Supplier_status: 87,
        Supplier_name: 'https://mall.jw2008.cn/easyExchange?from=1&smsSpecId=627&appkey=ZDLq9Vks6B7g0Qsk5CeOt7MkVD92Bq'
    },
    {
        Supplier_status: 88,
        Supplier_name: 'https://api.jifenfu.net/fun_recharge/html/recharge_template/recharge.html?pageNumber=2020120311212478138'
    },
    {
        Supplier_status: 89,
        Supplier_name: 'https://wechat.chengquan.cn/toExchange/index'
    },
    {
        Supplier_status: 90,
        Supplier_name: 'https://vm.verymas.com/web?activityId=78&exchangeCode=41cc4993o353d7'
    },
    {
        Supplier_status: 91,
        Supplier_name: 'https://fuliduo.net.cn'
    },
    {
        Supplier_status: 92,
        Supplier_name: 'https://pro.xiaojiancloud.com'
    },
    {
        Supplier_status: 93,
        Supplier_name: 'https://kaquanmall.fulu.com/?codeid=UpSe94Tc'
    },
    {
        Supplier_status: 94,
        Supplier_name: '成都雷歌'
    },
    {
        Supplier_status: 95,
        Supplier_name: 'https://wechat.chengquan.cn/toExchange/index橙卷腾讯视频超级影视月卡'
    },
    {
        Supplier_status: 96,
        Supplier_name: 'https://wechat.chengquan.cn/toExchange/index橙卷爱奇艺星钻会员月卡'
    },
    {
        Supplier_status: 97,
        Supplier_name: 'https://dhvip.qinameng.com/dh/'
    },
    {
        Supplier_status: 98,
        Supplier_name: 'M券'
    },
    {
        Supplier_status: 99,
        Supplier_name: '星权益'
    },
    {
        Supplier_status: 100,
        Supplier_name: 'https://t.jifenfu.net/yZx0ib/芒果'
    },
    {
        Supplier_status: 101,
        Supplier_name: '海威365'
    },
    {
        Supplier_status: 102,
        Supplier_name: '天天运通公司（深圳佰讯）'
    },
    {
        Supplier_status: 103,
        Supplier_name: '福禄（周口蝉益行）'
    },
    {
        Supplier_status: 104,
        Supplier_name: 'dearvvip.cn'
    },
    {
        Supplier_status: 105,
        Supplier_name: 'https://56718.cn/aqy/2335b2hYQe 爱奇艺月链接'
    },
    {
        Supplier_status: 106,
        Supplier_name: 'https://promoa.ejiaofei.cn/网易云'
    },
    {
        Supplier_status: 107,
        Supplier_name: 'https://56718.cn/paHealth?productCode=mtqpajk010jk&voucherCode=b0639cde8ff646009b04baf134d56cc9 美团10红包'
    },
    {
        Supplier_status: 108,
        Supplier_name: '芒果一天卡卡云'
    },
    {
        Supplier_status: 109,
        Supplier_name: '四川佳诺（迈向未来公司）'
    },
    {
        Supplier_status: 110,
        Supplier_name: 'http://e.huixuanyoupin.cn'
    },
    {
        Supplier_status: 111,
        Supplier_name: '重庆云计划'
    },
    {
        Supplier_status: 112,
        Supplier_name: '杭州同袍'
    },
    {
        Supplier_status: 113,
        Supplier_name: '重庆集娱'
    },
    {
        Supplier_status: 114,
        Supplier_name: '交壹吧【带税】'
    },
    {
        Supplier_status: 115,
        Supplier_name: '亿奇达'
    },
    {
        Supplier_status: 116,
        Supplier_name: '蓝色兄弟【带税】'
    },
    {
        Supplier_status: 117,
        Supplier_name: '美狐【带税】'
    },
    {
        Supplier_status: 119,
        Supplier_name: '图新网络【带税】'
    },
    {
        Supplier_status: 120,
        Supplier_name: '图新网络【未税】'
    },
    {
        Supplier_status: 166,
        Supplier_name: '人工代充'
    },
    {
        Supplier_status: 167,
        Supplier_name: '循环下单'
    },
    {
        Supplier_status: 168,
        Supplier_name: '组合商品'
    },
    ]
};
const getters = {
    getname(state, num) {
        let name = ''
        state.supplier_list.forEach(item => {
            if (item.Supplier_status == num) {
                name = item.Supplier_name
            }
        })
        return name
    },
};
const actions = {};
const mutations = {};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}