<template>

    <div class="fa1">
        <div class="fa2">

        </div>
        <div class="fa4">
            <img src="../../assets/vips/wz.png" alt="" srcset="">
        </div>
        <div class="fa3">
            <div class="fa5">
                <div class="fa6 ">
                    <img src="../../assets/vips/logo.png" alt="" srcset="">
                </div>
                <div class="fa7 ">
                    <div class="fa8">
                        管理中心首页

                    </div>
                </div>
                <div class="fa9">
                    运营中心
                    <svg style="vertical-align: middle;width: 13px;" t="1736232395096" class="icon"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5502" width="15"
                        height="15">
                        <path
                            d="M512 734.9c-8.5 0-16.6-3.4-22.6-9.4L107.7 343.7c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l359 359.2 359.1-359.2c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3L534.6 725.5c-6 6-14.1 9.4-22.6 9.4z"
                            p-id="5503" fill="#ffffff"></path>
                    </svg>
                </div>
                <div class="fa9 f600">
                    <div class="fa10">
                    </div>
                    分销中心
                </div>
                <div class="fa9">
                    技术接入工作台
                </div>
            </div>
        </div>

        <div class="fa12">

            <div class="fa13">

                <div class="fa14">
                    账户管理
                </div>
                <div class="fa15">
                    现金账户
                </div>
                <div class="fa15">
                    分销账户
                </div>

                <div class="fa14">
                    分销管理
                </div>
                <div class="fa15">
                    分销概况
                </div>
                <div class="fa15 bacE0E">
                    <div class="fa16">

                    </div>
                    分销订单
                </div>

            </div>

            <div class="sa1">
                <div class="sa2">
                    <div class="sa3" style=" color: #3570BF;">
                        <div class="sa4"></div>
                        分销订单
                    </div>
                    <div class="sa3">
                        退款订单
                    </div>
                </div>

                <div class="sa5">
                    <!-- <svg focusable="false" data-icon="calendar" width="1em" height="1em" fill="currentColor"
                        aria-hidden="true" viewBox="64 64 896 896">
                        <path
                            d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                        </path>
                    </svg> -->
                    <el-form ref="form" :model="form" label-width="110px">
                        <el-form-item label="交易时间：">
                            <el-date-picker v-model="form.time" type="daterange" range-separator="~" :clearable="false"
                                start-placeholder="开始日期" end-placeholder="结束日期"
                                style="width: 206px;position: relative;">
                            </el-date-picker>
                            <div class="sa6" :style="{ 'background': numClo == 0 ? '#DBE7FD' : '' }" @click="time(0)">
                                今日
                            </div>
                            <div class="sa6" :style="{ 'background': numClo == 1 ? '#DBE7FD' : '' }" @click="time(1)">
                                昨日
                            </div>
                            <div class="sa6" :style="{ 'background': numClo == 7 ? '#DBE7FD' : '' }" @click="time(7)">
                                最近7天
                            </div>
                            <div class="sa6" :style="{ 'background': numClo == 30 ? '#DBE7FD' : '' }" @click="time(30)">
                                最近30天
                            </div>
                        </el-form-item>
                    </el-form>
                    <el-form ref="form" :model="form" label-width="110px" :inline="true">

                        <el-form-item label="商品类型：">
                            <el-select v-model="form.region" placeholder="请选择" style="width: 206px;">
                                <el-option label="类型1" value="shanghai"></el-option>
                                <el-option label="类型2" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="商品名称：">
                            <el-input v-model="form.name" style="width: 206px;" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="所属应用：">
                            <el-select v-model="form.region" placeholder="请选择" style="width: 206px;">
                                <el-option label="应用1" value="shanghai"></el-option>
                                <el-option label="应用2" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="订单状态：">
                            <el-select v-model="form.region" placeholder="请选择" style="width: 206px;">
                                <el-option label="充值成功" value="shanghai"></el-option>
                                <el-option label="充值失败" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="退款状态：">
                            <el-select v-model="form.region" placeholder="请选择" style="width: 206px;">
                                <el-option label="成功" value="shanghai"></el-option>
                                <el-option label="失败" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="结算状态：">
                            <el-select v-model="form.region" placeholder="请选择" style="width: 206px;">
                                <el-option label="已结算" value="shanghai"></el-option>
                                <el-option label="未结算" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="组合商品订单：">
                            <div style="width: 860px;display: flex;justify-content: space-between;">
                                <div>
                                    <div class="sa7" :style="{ 'background': faBlo ? '#DBE7FD' : '' }"
                                        @click="sum(true)">
                                        是
                                    </div>
                                    <div class="sa7" :style="{ 'background': !faBlo ? '#DBE7FD' : '' }"
                                        @click="sum(false)">
                                        否
                                    </div>
                                </div>
                                <div>
                                    <div class="cxCla">
                                        查询
                                    </div>
                                    <div class="cxCla1">
                                        导出
                                    </div>
                                </div>

                            </div>
                        </el-form-item>

                    </el-form>

                    <el-table :data="tableData" style="width: 100%"
                        :header-cell-style="{ background: '#F2F2F2', color: '#757278' }">
                        <el-table-column align="center" prop="date" label="订单编号" width="180">
                            <template slot-scope="scope">
                                <p v-if="scope.row.orderinput" class="content" @click="scope.row.orderinput = false">{{
                                    scope.row.order
                                    }}
                                </p>
                                <p v-else class="content"><el-input v-model="scope.row.order"
                                        @keydown.enter="scope.row.orderinput = true"
                                        @change="scope.row.orderinput = true"></el-input></p>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="name" label="商品名称" width="180">
                            <template slot-scope="scope">
                                <p v-if="scope.row.phoneinput" class="content" @click="scope.row.phoneinput = false">{{
                                    scope.row.phone
                                }}
                                </p>
                                <p v-else class="content"><el-input v-model="scope.row.phone"
                                        @keydown.enter="scope.row.phoneinput = true"
                                        @change="scope.row.phoneinput = true"></el-input></p>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="name" label="商品金额" width="">
                            <template slot-scope="scope">
                                <p v-if="scope.row.brandinput" class="content" @click="scope.row.brandinput = false">{{
                                    scope.row.brand
                                    }}
                                </p>
                                <p v-else class="content">
                                    <el-input v-model="scope.row.brand" @keydown.enter="scope.row.brandinput = true"
                                        @change="scope.row.brandinput = true">
                                    </el-input>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="name" label="订单状态" width="">
                            <template slot-scope="scope">

                                <p v-if="scope.row.goodsnameinput" class="content"  style="color:#4E74AF ;"
                                    @click="scope.row.goodsnameinput = false">
                                    {{ scope.row.goodsname }}</p>

                                <p v-else class="content">
                                    <el-input v-model="scope.row.goodsname"
                                        @keydown.enter="scope.row.goodsnameinput = true"
                                        @change="scope.row.goodsnameinput = true">
                                    </el-input>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="name" label="充值手机号" width="">
                            <template slot-scope="scope">
                                <p v-if="scope.row.facevalueinput" class="content"
                                    @click="scope.row.facevalueinput = false">
                                    {{ scope.row.facevalue }}</p>
                                <p v-else class="content"><el-input v-model="scope.row.facevalue"
                                        @keydown.enter="scope.row.facevalueinput = true"
                                        @change="scope.row.facevalueinput = true"></el-input>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="name" label="提交时间" width="">
                            <template slot-scope="scope">
                                <div style="width: 82px;">
                                    <p v-if="scope.row.statusinput" class="content1"
                                        @click="scope.row.statusinput = false">
                                        {{
                                            scope.row.status
                                        }}
                                    </p>
                                    <p v-else class="content1"><el-input v-model="scope.row.status"
                                            @keydown.enter="scope.row.statusinput = true"
                                            @change="scope.row.statusinput = true"></el-input></p>
                                </div>

                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="name" label="成功时间" width="">
                            <template slot-scope="scope">
                                <div style="width: 82px;">
                                    <p v-if="scope.row.timeinput" class="content1" @click="scope.row.timeinput = false">
                                        {{ scope.row.time }}
                                    </p>
                                    <p v-else class="content1"><el-input v-model="scope.row.time"
                                            @keydown.enter="scope.row.timeinput = true"
                                            @change="scope.row.timeinput = true"></el-input></p>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>


                </div>
            </div>




        </div>


    </div>

</template>

<script>
export default {
    data() {
        return {
            form: {
                time: []
            },
            numClo: 7,
            faBlo: false,
            tableData: [{
                order: '', phone: '', time: '', goodsname: '', brand: '', facevalue: '', status: '',
                orderinput: true, phoneinput: true, timeinput: true, goodsnameinput: true, brandinput: true, facevalueinput: true, statusinput: true
            }],
        }
    },
    mounted() {
        this.time(7)
    },
    methods: {
        sum(val) {
            this.faBlo = val
        },

        time(num) {
            this.numClo = num
            let date = new Date();
            let myDate = this.getDateRange(date, num, true)
            console.log(myDate, "myDatemyDate");
            this.form.time = myDate
        },

        getDateRange(dateNow, intervalDays, bolPastTime) {
            let oneDayTime = 24 * 60 * 60 * 1000;
            let list = [];
            let lastDay;

            if (bolPastTime == true) {
                lastDay = new Date(dateNow.getTime() - intervalDays * oneDayTime);
                list.push(this.formateDate(lastDay));
                list.push(this.formateDate(dateNow));
            } else {
                lastDay = new Date(dateNow.getTime() + intervalDays * oneDayTime);
                list.push(this.formateDate(dateNow));
                list.push(this.formateDate(lastDay));
            }
            return list;
        },
        formateDate(time) {
            let year = time.getFullYear()
            let month = time.getMonth() + 1
            let day = time.getDate()

            if (month < 10) {
                month = '0' + month
            }

            if (day < 10) {
                day = '0' + day
            }

            return year + '-' + month + '-' + day + ''

        },


    }

}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    // padding: 0; 
    list-style: none;
}

.fa1 {
    width: 100%;
    height: 100%;
    background: #F6F8F9;

    /deep/ .el-date-editor .el-range-separator {
        width: 8%;
    }

    /deep/ .el-date-editor .el-range__icon {
        position: absolute;
        right: 9px;
        top: 1px;
    }

    /deep/ .el-form-item__label {
        padding: 0px;
    }

    /deep/ .el-table--small {
        font-size: 13px;
    }

}

.fa2 {
    height: 40px;
    width: 100%;
}

.fa4 {
    width: 100%;
}

.fa3 {
    width: 100%;
    height: 70px;
    background-image: linear-gradient(-44deg, #4b78c8 12%, #254397);
}


.fa5 {
    width: 1180px;
    height: 100%;
    margin: 0px auto;
    color: #fff;
    display: flex;

}

.fa6 {
    padding-right: 34px;

    img {
        width: 231px;
        box-sizing: border-box;
        padding-top: 21px;
        // vertical-align: middle;
    }
}

.fa7 {
    min-width: 130px;
    height: 100%;
    font-size: 16px;
    line-height: 70px;
    padding: 0px 15px;
    padding-right: 100px;
    position: relative;
}



.fa8:before {
    content: "";
    position: absolute;
    left: 0;
    top: 22px;
    width: 1px;
    height: 29px;
    background-color: #446bd9;
}

.fa9 {
    min-width: 130px;
    height: 100%;
    font-size: 16px;
    line-height: 70px;
    text-align: center;
    position: relative;

    .fa10 {
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -47px;
        bottom: 0px;
        width: 94px;
        height: 2px;
        background: #fff;
    }
}



.fa12 {
    width: 1440px;
    margin: 0px auto;
    display: flex;
    padding-bottom: 16px;
    padding: 20px;
}

.fa13 {
    width: 170px;
    height: 77vh;
    background: #fff;

}

.fa14 {
    font-size: 16px;
    color: #595959;
    font-weight: 600;
    padding: 10px 28px;
    border-radius: 3px;

}

.fa15 {

    font-size: 14px;
    color: #595959;
    padding: 10px 45px;
    border-radius: 3px;
    position: relative;

    .fa16 {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border-right: 3px solid #3570BF;


    }

}

.sa1 {
    width: 1100px;
    margin-left: 20px;
    background: #fff;
}

.sa2 {
    width: 100%;
    padding: 12px;
}

.sa3 {
    width: 80px;
    height: 32px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    position: relative;

    .sa4 {
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -28px;
        bottom: 0px;
        width: 37px;
        height: 2px;
        background: #3570BF;
    }
}

.sa5 {
    width: 100%;
    padding: 30px;
    padding-top: 56px;
}

.sa6 {
    display: inline-block;
    padding: 0px 10px;
    background: #EFEFEF;
    color: #595959;
    cursor: pointer;
    margin-left: 18px;
    font-size: 12px;
}

.sa7 {
    display: inline-block;
    padding: 0px 15px;
    background: #EFEFEF;
    color: #595959;
    cursor: pointer;
    margin-right: 10px;
    font-size: 12px;
}

.cxCla {
    display: inline-block;
    padding: 0px 30px;
    background: #235DFD;
    color: #fff;
    border-radius: 5px;
    margin-right: 10px;
}

.cxCla1 {
    display: inline-block;
    padding: 0px 30px;
    border-radius: 5px;
    color: #235DFD;
    background: #fff;
    border: 1px solid #235DFD;
}






















.content {
    width: 98%;
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    font-size: 14px;

    .el-input {
        width: 96%;
        margin-left: 0 !important;
    }
}

.content1 {
    // width: 80px;
    width: 100%;
    cursor: pointer;
    height: 50px;
    // line-height: 50px;
    font-size: 14px;
    margin-left: 18px;

    .el-input {
        width: 96%;
        margin-left: 0 !important;
    }
}

.bacE0E {
    background: #E0E9FF;

}


.f600 {
    font-weight: 600;
}

.inline-block {
    display: inline-block;
}
</style>