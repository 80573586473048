import { render, staticRenderFns } from "./xmly.vue?vue&type=template&id=3475d920&scoped=true&"
import script from "./xmly.vue?vue&type=script&lang=js&"
export * from "./xmly.vue?vue&type=script&lang=js&"
import style0 from "./xmly.vue?vue&type=style&index=0&id=3475d920&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3475d920",
  null
  
)

export default component.exports